const RRect = (
	ctx: CanvasRenderingContext2D,
	gridX: number,
	gridY: number,
	squareLength: number,
	shrinkFactor: number,
	paddingOffset: number,
	cornerRadius: number
) => {
	const posX: number = (gridX + 1) * squareLength - paddingOffset / 2 - 24;
	const posY: number = (gridY + 1) * squareLength - paddingOffset / 2 - 16;
	// ctx.fillRect(
	// 	(gridX + 1) * squareLength - paddingOffset / 2 - 24,
	// 	(gridY + 1) * squareLength - paddingOffset / 2 - 16,
	// 	squareLength,
	// 	squareLength
	// );
	ctx.beginPath();
	ctx.moveTo(posX + shrinkFactor + cornerRadius, posY + shrinkFactor);
	ctx.lineTo(posX - shrinkFactor - cornerRadius, posY + shrinkFactor);
	ctx.arcTo(
		posX + squareLength - shrinkFactor,
		posY + shrinkFactor,
		posX + squareLength - shrinkFactor,
		posY + squareLength - shrinkFactor + cornerRadius,
		cornerRadius
	);
	ctx.lineTo(
		posX + squareLength - shrinkFactor,
		posY + squareLength - shrinkFactor - cornerRadius
	);
	ctx.arcTo(
		posX + squareLength - shrinkFactor,
		posY + squareLength - shrinkFactor,

		posX + shrinkFactor - cornerRadius,
		posY + squareLength - shrinkFactor,
		cornerRadius
	);
	ctx.lineTo(
		posX + shrinkFactor + cornerRadius,
		posY + squareLength - shrinkFactor
	);
	ctx.arcTo(
		posX + shrinkFactor,
		posY + squareLength - shrinkFactor,
		posX + shrinkFactor,
		posY + shrinkFactor - cornerRadius,
		cornerRadius
	);
	ctx.lineTo(posX + shrinkFactor, posY + shrinkFactor + cornerRadius);
	ctx.arcTo(
		posX + shrinkFactor,
		posY + shrinkFactor,
		posX + shrinkFactor + cornerRadius,
		posY + shrinkFactor,
		cornerRadius
	);
	ctx.fill();
};

export default RRect;
