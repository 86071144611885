import React from "react";
import SequenceFinder from "./Games/SequenceFinder/SequenceFinder";
import { Center } from "@chakra-ui/react";

const TempHome = () => {
	return (
		<div className='Home'>
			<header className='Home-header'></header>
			<Center>
				<SequenceFinder />
			</Center>
		</div>
	);
};

export default TempHome;
