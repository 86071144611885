import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Loading } from "react-loading-dot";

const WaitingScreen = () => {
	return (
		<Banner>
			<div>waiting for other players to join</div>
			<Loading background={"#2096bd"} />
		</Banner>
	);
};

export default WaitingScreen;

const Banner = styled.div`
	font-size: 6vh;
	text-align: center;
	width: 100%;
	// height: 100vh;
	padding-top: 35vh;
	padding-bottom: 57vh;
	background-color: #192930;
	color: #2096bd;
`;
