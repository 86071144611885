import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

//pages here
import TempHome from "./components/TempHome";
import WaitingScreen from "./components/WaitingScreen";

import history from "./components/History";

history.listen((location: { pathname: any }) => {
	history.go(location.pathname);
});

//routes here
const Routes = () => {
	return (
		<Router>
			<div id='outer-div'>
				<Switch>
					<Route exact path='/'>
						<TempHome />
					</Route>
					<Route exact path='/waiting'>
						<WaitingScreen />
					</Route>
				</Switch>
			</div>
		</Router>
	);
};

export default Routes;
