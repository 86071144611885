const BorderBox = (
	ctx: CanvasRenderingContext2D,
	posX: number,
	posY: number,
	width: number,
	height: number,
	cornerRadius: number
) => {
	// ctx.fillRect(posX, posY, width, height);
	ctx.beginPath();
	ctx.moveTo(posX + cornerRadius, posY);
	ctx.lineTo(posX + width - cornerRadius, posY);
	ctx.arcTo(
		posX + width,
		posY,
		posX + width,
		posY + cornerRadius,
		cornerRadius
	);
	ctx.lineTo(posX + width, posY + height - cornerRadius);
	ctx.arcTo(
		posX + width,
		posY + height,
		posX + width - cornerRadius,
		posY + height,
		cornerRadius
	);
	ctx.lineTo(posX + cornerRadius, posY + height);
	ctx.arcTo(
		posX,
		posY + height,
		posX,
		posY + height - cornerRadius,
		cornerRadius
	);
	ctx.lineTo(posX, posY + cornerRadius);
	ctx.arcTo(posX, posY, posX + cornerRadius, posY, cornerRadius);
	ctx.fill();
};

export default BorderBox;
