import RRect from "./Shapes/RRect";
import HighlightLine from "./Shapes/HighlightLine";
import BorderBox from "./Shapes/BorderBox";
import { displayPartsToString } from "typescript";
// coordinate type
interface Coordinate {
	x: number;
	y: number;
}

interface Puzzle {
	key: number[];
	solution: number[];
}

class Board {
	// stores the size of canvas
	CANVAS_WIDTH: number = 1000;
	CANVAS_HEIGHT: number = 600;
	// stores difficulty (the length of the string we are trying to find)
	DIFFICULTY: number = 6;
	GRID_SIZE: number = 8;
	PALLET_SIZE: number = 10;
	PADDING_OFFSET: number = 50;

	// stores the grid array
	grid: number[][] = [];
	// we pick only 16 different numbers in order to make the puzzle easier
	pallet: number[] = [];
	// solution to the puzzle
	solution: number[] = [];
	// solution key (the sequence you are trying to find)
	// in the format of first two numbers are x and y coords of starting square
	// then it's x coord of second square (as it must be on the same row)
	// then y coord of third and so on
	key: number[] = [];

	gameOver: boolean = false;

	// coords that the user has selected so far
	selected: Coordinate[] = [];

	// stores the currently highlighted square if one is highlighted
	// highlighted?: Coordinate = undefined;
	highlighted?: Coordinate = { x: 3, y: 3 };

	constructor(canvas_width?: number, canvas_height?: number) {
		// adjust height and width if provided
		this.CANVAS_WIDTH = canvas_width ? canvas_width : this.CANVAS_WIDTH;
		this.CANVAS_HEIGHT = canvas_height ? canvas_height : this.CANVAS_HEIGHT;

		// populate number pallet
		while (this.pallet.length < this.PALLET_SIZE) {
			let random: number = Math.floor(Math.random() * 100);
			if (!this.pallet.includes(random)) this.pallet.push(random);
		}

		// populate grid
		for (let i: number = 0; i < this.GRID_SIZE; i++) {
			let tempList: number[] = [];
			for (let j: number = 0; j < this.GRID_SIZE; j++) {
				tempList.push(
					this.pallet[Math.floor(Math.random() * this.PALLET_SIZE)]
				);
			}
			this.grid.push(tempList);
		}

		//generate key and solution
		let puzzle: Puzzle = this.GenerateKeyAndSolution();
		this.solution = puzzle.solution;
		this.key = puzzle.key;
	}

	// draw all elements on the board
	draw = (ctx: CanvasRenderingContext2D) => {
		// checks for and handles game over
		if (this.selected.length == this.DIFFICULTY && !this.gameOver) {
			this.gameOver = true;
			this.flash(ctx);
		}

		// stores the shrink factor of hover square
		const shrinkFactor = 10;
		// box corner radius for hover square
		const cornerRadius = 10;

		// draw background
		ctx.fillStyle = "#192930";
		ctx.fillRect(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT);

		let squareLength =
			(this.CANVAS_HEIGHT - this.PADDING_OFFSET) / this.GRID_SIZE;

		ctx.fillStyle = "#33a17e";
		// draw selected squares
		for (let index: number = 0; index < this.selected.length; index++) {
			let square: Coordinate = this.selected[index];
			RRect(
				ctx,
				square.x,
				square.y,
				squareLength,
				shrinkFactor,
				this.PADDING_OFFSET,
				cornerRadius
			);
			// draw lines between selected
			if (index > 0) {
				HighlightLine(
					ctx,
					this.selected[index - 1].x,
					this.selected[index - 1].y,
					this.selected[index].x,
					this.selected[index].y,
					squareLength,
					shrinkFactor,
					this.PADDING_OFFSET
				);
			}
		}

		// draw highlighted square if one is highlighted
		if (this.highlighted && this.selected.length != this.DIFFICULTY) {
			ctx.fillStyle = "#2096bd";
			//draw line to highlighted square
			if (this.selected.length > 0) {
				HighlightLine(
					ctx,
					this.selected[this.selected.length - 1].x,
					this.selected[this.selected.length - 1].y,
					this.highlighted.x,
					this.highlighted.y,
					squareLength,
					shrinkFactor,
					this.PADDING_OFFSET
				);
			}
			RRect(
				ctx,
				this.highlighted.x,
				this.highlighted.y,
				squareLength,
				shrinkFactor,
				this.PADDING_OFFSET,
				cornerRadius
			);
		}

		// we now have to redraw the most recently selected square over line to highlighted square
		if (this.selected.length != 0) {
			ctx.fillStyle = "#33a17e";
			RRect(
				ctx,
				this.selected[this.selected.length - 1].x,
				this.selected[this.selected.length - 1].y,
				squareLength,
				shrinkFactor,
				this.PADDING_OFFSET,
				cornerRadius
			);
		}

		// draw numbers
		for (let i: number = 0; i < this.GRID_SIZE; i++) {
			for (let j: number = 0; j < this.GRID_SIZE; j++) {
				ctx.fillStyle = "white";
				ctx.font = String(squareLength * 0.5) + "px Roboto";
				ctx.fillText(
					this.DoubleCharString(this.grid[i][j]),
					squareLength * i + this.PADDING_OFFSET / 2 + 10,
					squareLength * (j + 1) + this.PADDING_OFFSET / 2 - 20
				);
			}
		}

		// box decorations
		for (let i: number = 0; i <= this.GRID_SIZE; i++) {
			for (let j: number = 0; j <= this.GRID_SIZE; j++) {
				// draw lines between numbers to better separate them
				ctx.fillStyle = "#2096bd";
				// vertical line
				if (j != this.GRID_SIZE) {
					ctx.fillRect(
						squareLength * i + this.PADDING_OFFSET / 2 - 8,
						squareLength * j + this.PADDING_OFFSET / 2 + squareLength / 4,
						5,
						squareLength / 2
					);
					this.FillCircle(
						ctx,
						squareLength * i + this.PADDING_OFFSET / 2 - 8 + 2.5,
						squareLength * j + this.PADDING_OFFSET / 2 + squareLength / 4,
						2.5
					);
					this.FillCircle(
						ctx,
						squareLength * i + this.PADDING_OFFSET / 2 - 8 + 2.5,
						squareLength * j + this.PADDING_OFFSET / 2 + (3 * squareLength) / 4,
						2.5
					);
				}
				// horizontal line
				if (i != this.GRID_SIZE) {
					ctx.fillRect(
						squareLength * i - 6 + this.PADDING_OFFSET / 2 + squareLength / 4,
						squareLength * j + this.PADDING_OFFSET / 2,
						squareLength / 2,
						5
					);
					this.FillCircle(
						ctx,
						squareLength * i - 6 + this.PADDING_OFFSET / 2 + squareLength / 4,
						squareLength * j + 2.5 + this.PADDING_OFFSET / 2,
						2.5
					);
					this.FillCircle(
						ctx,
						squareLength * i -
							6 +
							this.PADDING_OFFSET / 2 +
							(3 * squareLength) / 4,
						squareLength * j + 2.5 + this.PADDING_OFFSET / 2,
						2.5
					);
				}
			}
		}

		// draws the key
		this.drawKey(ctx, this.key);
	};

	drawKey(ctx: CanvasRenderingContext2D, key: number[]) {
		// display border box
		ctx.fillStyle = "#2e373b";
		BorderBox(ctx, 630, 55, 330, 65, 25);
		// display each number in the key
		for (let display: number = 0; display < key.length; display++) {
			// grey out numbers you have already selected
			if (display < this.selected.length) ctx.fillStyle = "#304954";
			else ctx.fillStyle = "#5ac8ed";
			ctx.fillText(
				this.DoubleCharString(key[display]),
				650 + 50 * display,
				100
			);
		}
	}

	// runs flash animation
	flash(
		ctx: CanvasRenderingContext2D,
		count: number = 0,
		gettingBrighter: boolean = true,
		flashCount: number = 0
	) {
		const max_flash = 2;
		this.draw(ctx);
		// flash color for all flashing elements
		ctx.fillStyle = "#ffffff" + count.toString(16);

		// stores the shrink factor of hover square
		const shrinkFactor = 10;
		// box corner radius for hover square
		const cornerRadius = 10;

		let squareLength =
			(this.CANVAS_HEIGHT - this.PADDING_OFFSET) / this.GRID_SIZE;
		// draw selected squares with flash color
		for (let index: number = 0; index < this.selected.length; index++) {
			let square: Coordinate = this.selected[index];
			RRect(
				ctx,
				square.x,
				square.y,
				squareLength,
				shrinkFactor,
				this.PADDING_OFFSET,
				cornerRadius
			);
			// draw lines between selected
			if (index > 0) {
				HighlightLine(
					ctx,
					this.selected[index - 1].x,
					this.selected[index - 1].y,
					this.selected[index].x,
					this.selected[index].y,
					squareLength,
					shrinkFactor,
					this.PADDING_OFFSET
				);
			}
		}
		// draw border box with new flash color
		BorderBox(ctx, 630, 55, 330, 65, 25);
		if (gettingBrighter) {
			if (count < 0xf0)
				setTimeout(
					() => this.flash(ctx, count + 16, gettingBrighter, flashCount),
					10
				);
			else setTimeout(() => this.flash(ctx, count - 16, false, flashCount), 10);
		} else {
			if (count > 0x10)
				setTimeout(
					() => this.flash(ctx, count - 16, gettingBrighter, flashCount),
					10
				);
			else if (flashCount < max_flash - 1)
				this.flash(ctx, 0, true, flashCount + 1);
		}
	}

	GenerateKeyAndSolution(): Puzzle {
		let solution: number[] = [];
		let key: number[] = [];
		// generate solution
		solution.push(Math.floor(Math.random() * this.GRID_SIZE));
		solution.push(Math.floor(Math.random() * this.GRID_SIZE));
		while (solution.length < this.DIFFICULTY + 1) {
			let random: number | undefined = undefined;
			while (!random || random == solution[solution.length - 2]) {
				random = Math.floor(Math.random() * this.GRID_SIZE);
			}
			solution.push(random);
		}

		// generate key
		let seenSquares: Coordinate[] = [];
		seenSquares.push({ x: solution[0], y: solution[1] });
		key.push(this.grid[solution[0]][solution[1]]);
		// iterate over the solution to find the necessary key
		for (let i: number = 2; i < solution.length; i++) {
			if (i % 2 == 0) {
				key.push(this.grid[solution[i]][solution[i - 1]]);
				// make sure the solution doesn't use the same square twice
				if (
					seenSquares.some(
						(elem) => elem.x == solution[i] && elem.y == solution[i - 1]
					)
				) {
					//if we find that we use the same square twice then find a new solution
					return this.GenerateKeyAndSolution();
				} else seenSquares.push({ x: solution[i], y: solution[i - 1] });
			} else {
				key.push(this.grid[solution[i - 1]][solution[i]]);
				// make sure the solution doesn't use the same square twice
				if (
					seenSquares.some(
						(elem) => elem.x == solution[i - 1] && elem.y == solution[i]
					)
				) {
					//if we find that we use the same square twice then find a new solution
					return this.GenerateKeyAndSolution();
				} else
					seenSquares.push({ x: this.solution[i - 1], y: this.solution[i] });
			}
		}

		return {
			key,
			solution,
		};
	}

	handleClick = (canvas: HTMLCanvasElement | null, event: React.MouseEvent) => {
		if (!canvas || !this.highlighted) return;
		let currentHover: Coordinate = this.highlighted;

		if (
			currentHover.x >= 0 &&
			currentHover.x < 8 &&
			currentHover.y >= 0 &&
			currentHover.y < 8
		) {
			// checks that we are selecting a new square and that it's value is the next value in the key
			if (
				!this.selected.some(
					(elem) => elem.x == currentHover.x && elem.y == currentHover.y
				) &&
				this.GetGridValue(currentHover) == this.key[this.selected.length]
			)
				this.selected.push(currentHover);
			// toggle clicked square if it was the most recent square clicked
			else if (
				this.selected.length != 0 &&
				this.selected[this.selected.length - 1].x == currentHover.x &&
				this.selected[this.selected.length - 1].y == currentHover.y
			)
				this.selected.pop();
			let ctx = canvas.getContext("2d");
			if (ctx) this.draw(ctx);
		}
	};

	handleHover = (canvas: HTMLCanvasElement | null, event: React.MouseEvent) => {
		if (!canvas) return;
		let rect: DOMRect = canvas.getBoundingClientRect();
		let realX = event.clientX - rect.left;
		let realY = event.clientY - rect.top;
		let hoverCoord: Coordinate = this.currentSquare(realX, realY);
		if (
			// not yet finished the puzzle
			this.selected.length < this.DIFFICULTY &&
			hoverCoord.x >= 0 &&
			hoverCoord.x < 8 &&
			hoverCoord.y >= 0 &&
			hoverCoord.y < 8
		) {
			let ctx = canvas.getContext("2d");
			// handle case of first square selected
			if (this.selected.length == 0) {
				this.highlighted = hoverCoord;
				if (ctx) this.draw(ctx);
			} else if (this.selected.length % 2 != 0) {
				// if we are not on the first square then we must select something on the same
				// row if we have selected an odd number of squares so far
				this.highlighted = {
					x: hoverCoord.x,
					y: this.selected[this.selected.length - 1].y,
				};
				if (ctx) this.draw(ctx);
			} else {
				// if we are selected an even number then same column
				this.highlighted = {
					x: this.selected[this.selected.length - 1].x,
					y: hoverCoord.y,
				};
				if (ctx) this.draw(ctx);
			}
		} else this.highlighted = undefined;
	};

	// function that takes x and y coordinates and converts it to grid coordinates
	currentSquare(x: number, y: number): Coordinate {
		let squareLength =
			(this.CANVAS_HEIGHT - this.PADDING_OFFSET) / this.GRID_SIZE;
		return {
			x: Math.floor((x - this.PADDING_OFFSET / 2 + 5) / squareLength),
			y: Math.floor((y - this.PADDING_OFFSET / 2 - 3) / squareLength),
		};
	}

	// simple function to make sure single digits have a 0 before them
	DoubleCharString = (input: number) => {
		let temp: string = String(input);
		if (temp.length == 1) return "0" + temp;
		return temp;
	};

	// simple function to get the value in the grid at a specific coordinate
	GetGridValue(coord: Coordinate) {
		return this.grid[coord.x][coord.y];
	}

	// simple full circle drawing function
	FillCircle = (
		ctx: CanvasRenderingContext2D,
		x: number,
		y: number,
		radius: number
	) => {
		ctx.beginPath();
		ctx.moveTo(x - radius, y);
		ctx.arc(x, y, radius, 0, 2 * Math.PI);
		ctx.fill();
	};
}

export default Board;
