const HighlightLine = (
	ctx: CanvasRenderingContext2D,
	originX: number,
	originY: number,
	finalX: number,
	finalY: number,
	squareLength: number,
	shrinkFactor: number,
	paddingOffset: number
) => {
	// if we are drawing horizontal line or vertical line
	if (originY == finalY)
		ctx.fillRect(
			(originX + 1) * squareLength -
				paddingOffset / 2 -
				24 +
				squareLength / 2 +
				shrinkFactor,
			(originY + 1) * squareLength - paddingOffset / 2 - 16 + shrinkFactor,
			squareLength * (finalX - originX),
			squareLength - shrinkFactor * 2
		);
	else
		ctx.fillRect(
			(originX + 1) * squareLength - paddingOffset / 2 - 24 + shrinkFactor,
			(originY + 1) * squareLength -
				paddingOffset / 2 -
				16 +
				squareLength / 2 +
				shrinkFactor,
			squareLength - shrinkFactor * 2,
			squareLength * (finalY - originY)
		);
};

export default HighlightLine;
