// This is a simple game where you have to find a certain sequence of 2 digit numbers where the first humber is in the same colum as
// the second number, the third number is in the same row as the second number, and so on until you find the entire sequence or
// whoever gets the most numbers in order from the given sequence in 30 seconds
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Board from "./Board";

const SequenceFinder = () => {
	// ref used to find the canvas
	const canvasRef = useRef(null);
	// stores the size of canvas
	const CANVAS_WIDTH: number = 1000;
	const CANVAS_HEIGHT: number = 600;
	const board = new Board();

	// getting canvas context
	let canvasObj: HTMLCanvasElement | null = null;
	let ctx: CanvasRenderingContext2D | null = null;

	useEffect(() => {
		canvasObj = canvasRef.current;
		// have to cast to any because of ts bug
		let tsIsDumb: any = canvasObj;
		ctx = tsIsDumb ? tsIsDumb.getContext("2d") : null;
		if (ctx) board.draw(ctx);
	});

	return (
		<Canvas
			id='game-window'
			ref={canvasRef}
			width={String(CANVAS_WIDTH)}
			height={String(CANVAS_HEIGHT)}
			onClick={(event) => board.handleClick(canvasObj, event)}
			onMouseMove={(event) => board.handleHover(canvasObj, event)}
		/>
	);
};

export default SequenceFinder;

const Canvas = styled.canvas`
	margin-top: 50px;
	border: 1px solid black;
`;
